import React, { useRef, useState, useEffect } from "react";
import "@aws-amplify/ui-react/styles.css";
import "./leaderboard.css";
import "../../../helpers/shared-css/dashboard-styling.css";

export const Leaderboard = ({ allResults, generalTableData }) => {
    const [showUserEntryOnTop, setShowUserEntryOnTop] = useState(false);
    const [showUserEntryOnButtom, setShowUserEntryOnButtom] = useState(false);
    const [isAtBottom, setIsAtBottom] = useState(false);
    const [isAtTop, setIsAtTop] = useState(false);

    const [bottomNextToken, setBottomNextToken] = useState(allResults["IBISLeaderboardBottomHasNextToken"]);
    const [topNextToken, setTopNextToken] = useState(allResults["IBISLeaderboardTopHasNextToken"]);

    const globalLeaderboard = allResults["IBIS100-Leaderboard"] || [];
    const currentUser = generalTableData.username;
    const currentUserResult =
        allResults["IBIS100-Leaderboard"]?.filter(result => result.username === currentUser);

    const leaderboardRef = useRef(null);
    const userRowRef = useRef(null);

    useEffect(() => {
        // if (bottomNextToken) {
        //     globalLeaderboard.push({ username: "entry-loader" })
        // }
        // if (topNextToken) {
        //     globalLeaderboard.unshift({ username: "entry-loader" })
        // }
        const checkVisibility = () => {
            if (leaderboardRef.current && userRowRef.current) {
                const leaderboardRect = leaderboardRef.current.getBoundingClientRect();
                const userRowRect = userRowRef.current.getBoundingClientRect();

                setShowUserEntryOnTop(userRowRect.bottom < leaderboardRect.top);
                setShowUserEntryOnButtom(userRowRect.top > leaderboardRect.bottom);

                const scrollTop = leaderboardRef.current.scrollTop;
                const scrollHeight = leaderboardRef.current.scrollHeight;
                const clientHeight = leaderboardRef.current.clientHeight;

                setIsAtBottom(scrollTop + clientHeight >= scrollHeight - 5);
                setIsAtTop(scrollTop <= 5);
            }
        };

        const leaderboardElement = leaderboardRef.current;
        leaderboardElement.addEventListener("scroll", checkVisibility);

        checkVisibility();

        return () => leaderboardElement.removeEventListener("scroll", checkVisibility);
    }, []);

    useEffect(() => {
        const handlePageScroll = () => {
            if (leaderboardRef.current) {
                leaderboardRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
            }
        };

        window.addEventListener("scroll", handlePageScroll, { once: true });

        return () => {
            window.removeEventListener("scroll", handlePageScroll);
        };
    }, []);

    return (
        <div className="leaderboard-root">
            <div className="leaderboard-top">
                <h3 className="dashboard-text-h3-styling">Global Leaderboard</h3>
            </div>
            <div className="leaderboard-body">
                <div className="leaderboard-header">
                    <p>Rank</p>
                    <p>Username</p>
                    <p>Country</p>
                    <p>Score</p>
                </div>
                <div className="leaderboard-entries" ref={leaderboardRef}>
                    {showUserEntryOnTop && (
                        <div className="leaderboard-result-top">
                            <p>{currentUserResult[0].rank}</p>
                            <p>{currentUser}</p>
                            <p>{currentUserResult[0].country}</p>
                            <p style={{ fontWeight: 800 }}>
                                {parseFloat(currentUserResult[0].score).toFixed(2)}
                            </p>
                        </div>
                    )}
                    {globalLeaderboard.map((result, index) => {
                        if (result.username === "entry-loader") {
                            return (
                                <div key={"loading-spinner"} className="loading-more-users">
                                    <button>Load more</button>
                                </div>
                            );
                        }

                        return (
                            <div
                                key={result.username}
                                className={index % 2 === 0 ? "leaderboard-result-white" : "leaderboard-result-grey"}
                                style={{ background: currentUser === result.username ? "gold" : "" }}
                                ref={currentUser === result.username ? userRowRef : null}
                            >
                                <p>{result.rank}</p>
                                <p>{result.username}</p>
                                <p>{result.country}</p>
                                <p style={{ fontWeight: 800 }}>
                                    {parseFloat(result.score).toFixed(2)}
                                </p>
                            </div>
                        );
                    })}
                    {showUserEntryOnButtom && (
                        <div className="leaderboard-result-bottom" style={{background: "gold" }}>
                            <p>{currentUserResult[0].rank}</p>
                            <p>{currentUser}</p>
                            <p>{currentUserResult[0].country}</p>
                            <p style={{ fontWeight: 800 }}>
                                {parseFloat(currentUserResult[0].score).toFixed(2)}
                            </p>
                        </div>
                    )}
                </div>

            </div>
        </div>
    );
};

export default Leaderboard;
