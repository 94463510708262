import React, { useState, useEffect, useRef } from "react";
import "@aws-amplify/ui-react/styles.css";
import "./practice-test.css";
import PracticeTestHeader from "../components/1. header/header";
import PracticeTestNavigation from "../components/2. navigation/navigation";
import Stats from "../components/3. stats/stats";
import Leaderboard from "../components/4. leaderboard/leaderboard";
import Graphs from "../components/5. graphs/graphs";
import "../../helpers/shared-css/no-data.css"
import { IoNavigateOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../helpers";
import Feedback from "../components/6. feedback/feedback";

const TEST_ID = "Practice test";

export const PracticeTest = ({ generalTableData, setSelectedTestId, allResults, setSelectedTestNumber }) => {
  const navigate = useNavigate();

  const [showTestResults, setShowTestResults] = useState(0);

  const [seeResults, setSeeResults] = useState(false);
  const seeResultsRef = useRef(null);
  useEffect(() => {
    if (allResults[`${TEST_ID}-Leaderboard`] !== undefined) {
      setShowTestResults(allResults[`${TEST_ID}-Leaderboard`]);
    } else {
      setShowTestResults([]);
    }
  }, [allResults]);

  if (seeResults) {
    if (seeResultsRef.current) {
      seeResultsRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
      setSeeResults(false);
    };
  }

  const navigateToTestPortal = () => {
    setSelectedTestId(TEST_ID);
    const params = new URLSearchParams({
      previousUrl: 'practiceTest',
    });
    navigate(`${Paths.TestPortal}?${params.toString()}`);
  }

  if (Object.keys(allResults).length === 0) {
    return (
      <div></div>
    )
  }
  return (  
    <div className="practice-test-root">
      <PracticeTestHeader />
      <PracticeTestNavigation generalTableData={generalTableData} setSelectedTestId={setSelectedTestId} setSeeResults={setSeeResults} setSelectedTestNumber={setSelectedTestNumber} />
      {
        showTestResults.length > 0 ?
          <div className="test-taken-div">
            <Stats allResults={allResults} generalTableData={generalTableData} />
            <Leaderboard allResults={allResults} generalTableData={generalTableData} />
            <Graphs allResults={allResults} generalTableData={generalTableData} seeResultsRef={seeResultsRef} />
            <Feedback allResults={allResults} />
          </div>
          :
          <div className="analytics-no-data">
            <h3 className="dashboard-text-h3-styling">You have not taken the Practice test yet. To begin, start the test here:</h3>
            <button onClick={navigateToTestPortal}>
              Begin test <IoNavigateOutline />
            </button>
          </div>
      }
    </div>
  );
};

export default PracticeTest;
