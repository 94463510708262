import React from "react";
import "@aws-amplify/ui-react/styles.css";
import "./stats.css";
import "../../../helpers/shared-css/dashboard-styling.css";
import { IoIosPeople } from "react-icons/io";
import { FaAward } from "react-icons/fa";
import { FaRankingStar } from "react-icons/fa6";
import { GrScorecard } from "react-icons/gr";

const TEST_ID = "Practice test";

export const Stats = ({ allResults, generalTableData }) => {
    const globalResult = allResults[`${TEST_ID}-Graphs`]["globalResult"];
    const practiceTestRanking = allResults[`${TEST_ID}-Leaderboard`] ? allResults[`${TEST_ID}-Leaderboard`].
        find(result => result.username === generalTableData.username)?.rank ?? '-' : '-';
    const practiceTestAverage = allResults[`${TEST_ID}-Leaderboard`] ? allResults[`${TEST_ID}-Leaderboard`].
        find(result => result.username === generalTableData.username)?.score ?? '-' : '-';

    const smallStats = [
        {
            text: "Participants",
            value: globalResult["participationCount"],
            color: "green",
        },
        {
            text: "Your ranking",
            value: practiceTestRanking,
            color: "blue",
        },
        {
            text: "Test score",
            value: `${parseFloat(practiceTestAverage).toFixed(2)}%`,
            color: "yellow",
        }
    ]

    return (
        <div className="practice-test-stats-root">
            <div className="dashboard-text-header-div-styling">
                <h3 className="dashboard-text-h3-styling">Performance</h3>
            </div>
            <div className="practice-test-stats">
                {
                    smallStats.map(stat => {
                        return (
                            <div className={`small-stat-${stat.color}`}>
                                {
                                    stat.text === "Members" ? <IoIosPeople /> : stat.text === "Your ranking" ? <FaRankingStar /> : <GrScorecard />
                                }
                                <p className="dashboard-text-p-styling">{stat.text}</p>
                                <h3 className="dashboard-text-h3-styling">{stat.value}</h3>
                            </div>
                        )
                    })
                }
                <div className="big-stat">

                </div>
            </div>
        </div>
    );
};

export default Stats;