import React from "react";
import "./profile-non-edit.css";
import "../../../helpers/shared-css/dashboard-styling.css";
import { CiUser } from "react-icons/ci";
import { MdOutlineMail } from "react-icons/md";
import { FaRegFlag } from "react-icons/fa";
import { MdSchool } from "react-icons/md";
import { VscSymbolNamespace } from "react-icons/vsc";
import { LiaBirthdayCakeSolid } from "react-icons/lia";
import { FaPhoneAlt } from "react-icons/fa";
import { FaTransgender } from "react-icons/fa";

const data = [
    ["username", "email", "country", "university"],
    ["fullName", "dob", "phoneNumber", "gender"]
]

const titles = [
    [{ title: "Username:", icon: <CiUser /> }, { title: "Email:", icon: <MdOutlineMail /> }, { title: "Country:", icon: <FaRegFlag /> }, { title: "University:", icon: <MdSchool /> }],
    [{ title: "Full name:", icon: <VscSymbolNamespace /> }, { title: "Date of birth:", icon: <LiaBirthdayCakeSolid /> }, { title: "Phone number:", icon: <FaPhoneAlt /> }, { title: "Gender:", icon: <FaTransgender /> }]
]

const ProfileNonEdit = ({ generalTableData }) => {
    return (
        <div className="profile-non-edit-root">
            {
                data.map((set, index) => {
                    return (
                        <div className="profile-set">
                            <div className="profile-titles">
                                {
                                    titles[index].map((title, i) => {
                                        return (<p>{title.icon}{title.title}</p>)
                                    })
                                }
                            </div>
                            <div className="profile-values">
                                {
                                    set.map(title => {
                                        const value = generalTableData[title];
                                        const doesNotExist = generalTableData[title] === undefined || !generalTableData[title];
                                        return (<p style={{color: doesNotExist ? 'red' : ''}}>{doesNotExist ? "empty" : value }</p>)
                                    })
                                }
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
};

export default ProfileNonEdit;
