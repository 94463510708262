import React, { useEffect, useState } from "react";
import "./home.css";
import DashboardHeading from "../components/1. heading/dashboard-heading";
import DashboardPerformance from "../components/2. performance/dashboard-performance";
import DashboardNavigation from "../components/3. dashboard-navigation/dashboard-navigation";
import DashboardTestHistory from "../components/4. test-history/dashboard-test-history";

const Home = ({ generalTableData, allResults, setSelectedTestId, setSelectedTestNumber }) => {
  const [highlightPurchaseTest, setHighlightPurchaseTest] = useState(false);
  useEffect(() => {
    const setVh = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    const handleTouchStart = () => {
      window.scrollTo(0, 1);
    };

    setVh();
    window.addEventListener("resize", setVh);
    document.addEventListener("touchstart", handleTouchStart);

    return () => {
      window.removeEventListener("resize", setVh);
      document.removeEventListener("touchstart", handleTouchStart);
    };
  }, []);

  return (
    <div className="home-container">
      <div className="white-background">
        <div className="home-components">
          <DashboardHeading generalTableData={generalTableData} setSelectedTestId={setSelectedTestId} setSelectedTestNumber={setSelectedTestNumber} setHighlightPurchaseTest={setHighlightPurchaseTest} />
          <DashboardPerformance generalTableData={generalTableData} allResults={allResults}/>
          <DashboardNavigation generalTableData={generalTableData} setSelectedTestId={setSelectedTestId} setSelectedTestNumber={setSelectedTestNumber} highlightPurchaseTest={highlightPurchaseTest} />
          <DashboardTestHistory generalTableData={generalTableData} setSelectedTestId={setSelectedTestId} setSelectedTestNumber={setSelectedTestNumber}/>
        </div>
      </div>
    </div>
  );
};

export default Home;
