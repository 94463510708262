import React, { useState } from "react";
import "./enter-pin-and-password.css";
import { confirmResetPassword } from "@aws-amplify/auth";
import { FaCheck, FaTimes } from "react-icons/fa";

const EnterPinAndPassword = ({ email, setPasswordReset }) => {
    const [passwordValid, setPasswordValid] = useState(false);
    const [reEnteredPasswordValid, setReEnteredPasswordValid] = useState(false);
    const [invalidPasswords, setInvalidPasswords] = useState(false);
    const [invalidCode, setInvalidCode] = useState(false);
    const [showPasswords, setShowPasswords] = useState(false);

    const onPasswordChange = () => {
        const password = document.getElementById("password").value;
        if (password.length > 7) {
            setPasswordValid(true);
        } else {
            setPasswordValid(false);
        }
    }

    const onReEnterPasswordChange = () => {
        const password = document.getElementById("password") ?
            document.getElementById("password").value : "";
        const reEnteredPassword = document.getElementById("re-entered-password") ?
            document.getElementById("re-entered-password").value : "";

        if (reEnteredPassword.length > 7 && reEnteredPassword === password) {
            setReEnteredPasswordValid(true);
        } else {
            setReEnteredPasswordValid(false);
        }
    }

    const resetPassword = async () => {
        const password = document.getElementById("password").value;
        const code = document.getElementById("pin").value;

        if (!passwordValid || !reEnteredPasswordValid) {
            setInvalidPasswords(true);
            return
        } else if (code.length < 4) {
            setInvalidCode(true);
            return
        }

        try {
            await confirmResetPassword({
                username: email,
                confirmationCode: document.getElementById("pin").value,
                newPassword: password,
            });
            setPasswordReset(true);
        } catch (error) {

        }
    }

    const showPasswordsOnClick = () => {
        setShowPasswords(!showPasswords);
    }

    return (
        <div className="enter-pin-root">
            <h1>Please enter the code sent to {email}, and your new password:</h1>
            <div className="enter-pin-div">
                <div className="enter-pin-input">
                    <p>Code:</p>
                    <input
                        id="pin"
                        type="text"
                        autocomplete="off"
                        placeholder="john.doe@gmail.com" />
                </div>
                <div className="enter-pin-input">
                    <p>Password:</p>
                    <div className="password-div">
                        <div className="password-input">
                            <input
                                id="password"
                                type={showPasswords ? "text":"password"}
                                autocomplete="off"
                                placeholder="Password"
                                onChange={onPasswordChange} />
                            <p onClick={showPasswordsOnClick}>{showPasswords ? "Hide password" : "Show password"}</p>
                        </div>
                        {
                            passwordValid ? <FaCheck style={{ color: 'green' }} /> : <FaTimes style={{ color: 'red' }} />
                        }
                    </div>
                </div>
                <div className="enter-pin-input">
                    <p>Re-enter password:</p>
                    <div className="password-div">
                        <div className="password-input">
                            <input
                                id="re-entered-password"
                                type={showPasswords ? "text":"password"}
                                autocomplete="off"
                                placeholder="Password"
                                onChange={onReEnterPasswordChange} />
                            <p onClick={showPasswordsOnClick}>{showPasswords ? "Hide password" : "Show password"}</p>
                        </div>
                        {
                            reEnteredPasswordValid ? <FaCheck style={{ color: 'green' }} /> : <FaTimes style={{ color: 'red' }} />
                        }
                    </div>
                </div>
                <button onClick={resetPassword}>Reset Password</button>
                {
                    invalidCode ? <p className="reset-error-message">The code you have entered is invalid.</p> :
                        invalidPasswords ? <p className="reset-error-message">The password you have provided is invalid.</p> : null
                }
            </div>
        </div>
    );
};

export default EnterPinAndPassword;
