import React, { useState } from "react";
import "./profile.css";
import "../../helpers/shared-css/dashboard-styling.css";
import ProfileHeader from "../components/1. profile-header/profile-header";
import ProfileNonEdit from "../components/2. profile-non-edit/profile-non-edit";
import ProfileEdit from "../components/3. profile-edit/profile-edit";

const Profile = ({
  idToken,
  setGeneralTableData,
  generalTableData,
  setInError,
}) => {

  const [inEditMode, setInEditMode] = useState(false);

  return (
    <div className="profile-root">
      <div className="profile">
        <div className="dashboard-text-header-div-styling">
          <h3 className="dashboard-text-h3-styling">Profile</h3>
          <p className="dashboard-text-p-styling">Filling in all profile information will allow the IBIS team to connect you to investment banking recruiters faster!</p>
        </div>
        <ProfileHeader
          generalTableData={generalTableData}
          inEditMode={inEditMode}
          setInEditMode={setInEditMode}
        />
        {
          inEditMode ?
            <ProfileEdit
              idToken={idToken}
              setGeneralTableData={setGeneralTableData}
              generalTableData={generalTableData}
              setInEditMode={setInEditMode}
              setInError={setInError}
            />
            :
            <ProfileNonEdit
              generalTableData={generalTableData}
            />
        }
      </div>
    </div>
  );
};

export default Profile;
