import React, { useRef, useState, useEffect } from "react";
import "./book-a-test.css";
import TermsAndConditions from "../components/1. terms-and-conditions/terms-and-conditions";
import PurchaseForm from "../components/2. purchase-form/purchase-form";
import StripePayment from "../components/3. stripe-payment/stripe-payment";
import PaymentComplete from "../components/4. payment-complete/payment-complete";

const BookATest = ({ idToken, setInError, generalTableData, setGeneralTableData }) => {
  const [stripePayment, setStripePayment] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const divRef = useRef(null);
  const [sessionId, setSessionId] = useState(null);
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    if (localStorage.getItem("sessionId")) {
      setSessionId(localStorage.getItem("sessionId"));
      localStorage.removeItem("sessionId");
    }
  }, [idToken]);

  return (
    <div className="bookatest-root" id="bookatest-root">
      {
        sessionId !== null ? (
          <PaymentComplete
            idToken={idToken}
            sessionId={sessionId}
            setSessionId={setSessionId}
            setInError={setInError}
            generalTableData={generalTableData}
            setGeneralTableData={setGeneralTableData}
          />
        ) :
          <div className={`bookatest ${stripePayment > 0 ? "blur-background" : ""}`} ref={divRef}>
            <div className="bookatest-components">
              <TermsAndConditions isChecked={isChecked} setIsChecked={setIsChecked} />
              {
                isChecked && 
                <PurchaseForm 
                  idToken={idToken}
                  setStripePayment={setStripePayment} 
                  setClientSecret={setClientSecret} 
                  setInError={setInError}
                />
              }
            </div>
          </div>
      }
      {stripePayment > 0 && (
        <div className="stripe-payment-overlay">
          <StripePayment
            setStripePayment={setStripePayment}
            clientSecret={clientSecret}
          />
        </div>
      )}
    </div>
  );
};

export default BookATest;
