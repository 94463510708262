import React from "react";
import "@aws-amplify/ui-react/styles.css";
import "./navigation.css";
import "../../../helpers/shared-css/dashboard-styling.css";
import { IoNavigateOutline } from "react-icons/io5";
import { Tooltip } from 'react-tooltip';
import { useNavigate } from "react-router-dom";
import { Paths } from "../../../helpers";

const TEST_ID = "Practice test";
const dashboardNavigationSteps = [
    {
        text: "1. Start a test",
        information: "Start the IBIS100 test.",
        buttonText: "Begin test",
    },
    {
        text: "2. See results",
        information: "See your results and statistics here.",
        buttonText: "See results",
    }
]

export const PracticeTestNavigation = ({ generalTableData, setSelectedTestId, setSeeResults, setSelectedTestNumber }) => {
    const testsPurchased = generalTableData.testsPurchased.filter(test => {
        if (test.id === TEST_ID) return test;
    }) ?? [];
    const testsCompleted = generalTableData.testsCompleted.filter(test => {
        if (test.id === TEST_ID) return test;
    }) ?? [];

    const navigate = useNavigate();

    const navigateToResults = () => {
        setSeeResults(true);
    }
    const navigateToTestPortal = () => {
        setSelectedTestNumber(`${TEST_ID}-01`);
        setSelectedTestId(TEST_ID);
        navigate(Paths.TestPortal);
    }


    return (
        <div className="practice-test-navigation-root">
            <div className="practice-test-navigation-steps">
                {
                    dashboardNavigationSteps.map(step => {
                        const disableStartTestButton =
                            step.text === "1. Start a test" && testsPurchased.length === 0;
                        const disableSeeResultsButton =
                            step.text === "2. See results" && testsCompleted.length === 0;
                        const disabledButtonText = disableSeeResultsButton ? "You must first complete a practice test." :
                            disableStartTestButton ? "You have completed the Practice test already." : null;
                        return (
                            <div className="practice-test-step">
                                <div className="dashboard-step-header">
                                    <h3 className="dashboard-text-h3-styling">{step.text}</h3>
                                    <p className="dashboard-text-p-styling">{step.information}</p>
                                </div>
                                <button
                                    id={step.buttonText}
                                    onClick={
                                    step.buttonText === "Begin test" && !disableStartTestButton ? 
                                        navigateToTestPortal
                                    : step.buttonText === "See results" && !disableSeeResultsButton ?  
                                        navigateToResults : null
                                    }
                                    data-tooltip-id={step.buttonText}
                                    data-tooltip-content={disabledButtonText}
                                    className={disableStartTestButton || disableSeeResultsButton ? "dashboard-step-button-disabled" : "dashboard-step-button"}
                                >
                                    {step.buttonText}<IoNavigateOutline />
                                </button>
                                {
                                    disableStartTestButton || disableSeeResultsButton ? <Tooltip id={step.buttonText} /> : null
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
};

export default PracticeTestNavigation;
