import React from "react";
import "@aws-amplify/ui-react/styles.css";
import "./feedback.css";

export const Feedback = () => {
  return (
    <div className="feedback-root">
      <label>feed back</label>
    </div>
  );
};

export default Feedback;
