import React, { useState } from "react";
import "./forgot-password.css";
import EnterUsername from "../components/1. enter-username/enter-username";
import EnterPinAndPassword from "../components/2. enter-pin-and-password/enter-pin-and-password";
import PasswordReset from "../components/3. password-reset/password-reset";

const ForgotPassword = () => {
  const [passwordReset, setPasswordReset] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [email, setEmail] = useState(null);

  return (
    <div className="forgot-password">
      {
        !emailSent ? <EnterUsername setEmailSent={setEmailSent} setEmail={setEmail} /> :
          !passwordReset && emailSent ? <EnterPinAndPassword email={email} setPasswordReset={setPasswordReset} /> :
            passwordReset && emailSent ? <PasswordReset /> : null
      }
    </div>
  );
};

export default ForgotPassword;
