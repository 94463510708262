import React, { useState, useEffect, useRef } from "react";
import "./test-session.css";
import "./page-left-warning.css";
import "../../helpers/shared-css/dashboard-styling.css";
import PostTest from "../components/post-test";
import { fetchRequest } from "../../helpers";
import Spinner from "../../spinner/spinner";
import { CgWebsite } from "react-icons/cg";
import PageEnteredIncorrectly from "../../helpers/shared-components/page-entered-incorrectly";
import { IoNavigateOutline } from "react-icons/io5";

const TestSession = ({ idToken, testData }) => {
  const itemsPerPage = 25;

  const [userAnswers, setUserAnswers] = useState([]);
  const [endDate, setEndDate] = useState("No Value Set")
  const [questionSet, setQuestionSet] = useState([]);
  const [noInProgressTest, setNoInProgressTest] = useState(false);
  const [testLength, setTestLength] = useState(0);
  const [index, setIndex] = useState(0);
  const [timeLeft, setTimeLeft] = useState(100);
  const [selectedOption, setSelectedOption] = useState("");
  const [questionText, setQuestionText] = useState("");
  const [answers, setAnswers] = useState([]);
  const [attemptEndSession, setAttemptEndSession] = useState(false);
  const [endSession, setEndSession] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showTestsStatus, setShowTestsStatus] = useState(false);
  const testsStatusRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageLeft, setPageLeft] = useState(0);
  const [inTestTab, setInTestTab] = useState(true);

  const page = index;

  useEffect(() => {
    if (testData !== "No Value Set") {
      const tempEndDate = new Date();
      tempEndDate.setSeconds(tempEndDate.getSeconds() + testData.examTimeLength);

      const currentTime = new Date().getTime();
      const remainingTimeMs = tempEndDate.getTime() - currentTime;
      const remainingTime = Math.floor(remainingTimeMs / 1000);
      setTimeLeft(remainingTime > 0 ? remainingTime : 0);
      setQuestionSet(testData.questions);
      setEndDate(tempEndDate.getTime());
      setTestLength(testData.examTimeLength);
      setQuestionText(testData.questions[0].questionText);
      setAnswers(testData.questions[0].answers);

      const tempUserAnswers = []
      for (let i = 0; i < testData.numberOfQuestions; i++) {
        tempUserAnswers[i] = "";
      }
      fetchRequest(idToken, 'updateInProgressTest', 'POST', {
        results: tempUserAnswers,
        endDate: new Date().getTime() + endDate,
      });
      setUserAnswers(tempUserAnswers);

    } else {
      setNoInProgressTest(true);
    }
  }, [testData])

  useEffect(() => {
    if (!noInProgressTest) {
      if (timeLeft === 0 && endDate !== "No Value Set") {
        yesOnClick();
        return;
      }

      const timerId = setInterval(() => {
        const remainingTimeMs = endDate - new Date().getTime();
        const remainingTime = Math.floor(remainingTimeMs / 1000);

        setTimeLeft(remainingTime > 0 ? remainingTime : 0);
      }, 1000);

      return () => clearInterval(timerId);
    }
  }, [timeLeft, testLength]);

  const handleClickOutside = (event) => {
    if (
      testsStatusRef.current &&
      !testsStatusRef.current.contains(event.target)
    ) {
      setShowTestsStatus(false);
    }
  };

  useEffect(() => {
    if (showTestsStatus) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showTestsStatus]);

  if (noInProgressTest) {
    return (
      <PageEnteredIncorrectly message="There is no active test session currently. Please go to 'MyTests', 'Your Upcoming Tests' and
      select a test you want to start."/>
    );
  }

  window.onblur = function (ev) {
    if (inTestTab) {
      setPageLeft(pageLeft + 1);
      setInTestTab(false);
    }
  };

  const maxPage = Math.ceil(questionSet.length / itemsPerPage) - 1;

  const handleNextPage = () => {
    if (currentPage < maxPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getPaginatedItems = () => {
    const start = currentPage * itemsPerPage;
    const end = start + itemsPerPage;
    return questionSet.slice(start, end);
  };

  function fireAndForget(updatedAnswers) {
    setTimeout(() => {
      fetchRequest(idToken, 'updateInProgressTest', 'POST', {
        results: updatedAnswers,
        endDate: new Date().getTime() + endDate,
      });
    }, 0);
  }

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    const updatedAnswers = [...userAnswers];
    updatedAnswers[index] = selectedValue;
    setUserAnswers(updatedAnswers);
    fireAndForget(updatedAnswers);
  };

  const handleSubmitTest = () => {
    setAttemptEndSession(true);
  };

  const handleNextQuestionClick = () => {
    const updatedAnswers = [...userAnswers];
    updatedAnswers[index] = selectedOption;
    setUserAnswers(updatedAnswers);

    const newIndex = index + 1;
    setIndex(newIndex);
    setQuestionText(questionSet[newIndex].questionText);
    setAnswers(questionSet[newIndex].answers);
    setSelectedOption(updatedAnswers[newIndex] || "");
  };

  const handlePreviousQuestionClick = () => {
    const updatedAnswers = [...userAnswers];
    updatedAnswers[index] = selectedOption;
    setUserAnswers(updatedAnswers);

    const newIndex = index - 1;
    setIndex(newIndex);
    setQuestionText(questionSet[newIndex].questionText);
    setAnswers(questionSet[newIndex].answers);
    setSelectedOption(updatedAnswers[newIndex] || "");
  };

  const postExamResults = async () => {
    setIsLoading(true);
    try {
      const timeTaken = Math.floor((testLength - (endDate - new Date().getTime()) / 1000))
      await fetchRequest(idToken, "deleteInProgressTest", "POST", {
        results: userAnswers,
        timeTaken: timeTaken,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const showTests = () => {
    setShowTestsStatus((prevState) => !prevState);
  };

  const noOnClick = () => {
    setAttemptEndSession(false);
  };

  const handleTestsStatusOnClick = (index) => {
    setIndex(index);
    setQuestionText(questionSet[index].questionText);
    setAnswers(questionSet[index].answers);
    setSelectedOption(userAnswers[index] || "");
  };

  const yesOnClick = async () => {
    await postExamResults();
    setEndSession(true);
  };

  const resetQuestion = () => {
    let tempUserAnswers = userAnswers;
    tempUserAnswers[index] = "";
    setUserAnswers(tempUserAnswers);
    setSelectedOption("");
    fireAndForget(tempUserAnswers);
  }

  if (!inTestTab && !endSession) {
    if (pageLeft > 3) {
      setInTestTab(true);
      yesOnClick();
    }
    return (
      <div className="page-left-root">
        <p className="dashboard-text-h3-styling">You may not leave the test tab. This is warning {pageLeft}, you have 3 warnings then your test will be ended automatically.</p>
        <button onClick={() => setInTestTab(true)}>Continue back to test <IoNavigateOutline /></button>
      </div>
    )
  }

  if (attemptEndSession && !endSession) {
    return (
      <div className="test-session">
        <div className="end-session">
          <h2>Are you sure you want to submit and end your test?</h2>
          <div className="end-session-buttons">
            <button onClick={noOnClick}>No<IoNavigateOutline /></button>
            <button onClick={!isLoading ? yesOnClick : null}>{!isLoading ? "Yes" : <Spinner />}{!isLoading ? <IoNavigateOutline /> : null}</button>
          </div>
        </div>
      </div>
    );
  } else if (endSession && !isLoading) {
    return <PostTest />;
  }

  return (
    <div className="test-session">
      <div className="test-session-div">
        <div className="test-session-timer">
          <p>
            {Math.floor(timeLeft / 60) < 10 ? `0${Math.floor(timeLeft / 60)}` : Math.floor(timeLeft / 60)}:{timeLeft % 60 < 10 ? `0${timeLeft % 60}` : timeLeft % 60}
          </p>
          <div className="test-session-tests-status">
            <CgWebsite className="test-session-icon" onClick={showTests} />
            {showTestsStatus && (
              <div className="tests-status" ref={testsStatusRef}>
                <div className="pagination-buttons">
                  <button
                    onClick={handlePreviousPage}
                    disabled={currentPage === 0}
                  >
                    Previous
                  </button>
                  <button
                    onClick={handleNextPage}
                    disabled={currentPage === maxPage}
                  >
                    Next
                  </button>
                </div>
                <div className="tests-status-grid">
                  {getPaginatedItems().map((_, index) => (
                    <div
                      key={index + (currentPage * 25)}
                      className="test-square"
                      onClick={() => handleTestsStatusOnClick(index + (currentPage * 25))}
                      style={{
                        backgroundColor: userAnswers[index + (currentPage * 25)] !== ""
                          ? "lightblue"
                          : "transparent",
                        borderColor: page === index + (currentPage * 25) ? 'red' : 'black',
                      }}
                    >
                      {index + 1 + (currentPage * 25)}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="test-session-question">
          <p>{questionText}</p>
        </div>
        <div className="test-session-answers">
          {answers.map((option, index) => (
            <label key={index}>
              <input
                type="radio"
                value={option}
                checked={selectedOption === option}
                onChange={handleOptionChange}
              />
              {option}
            </label>
          ))}
        </div>
        <div className="reset-test-question-div">
          <label onClick={resetQuestion}>Reset question</label>
        </div>
        <div className="test-session-bottom-navigation">
          {index !== 0 ? (
            <button onClick={handlePreviousQuestionClick}>
              Previous Question <IoNavigateOutline />
            </button>
          ) : null}
          {index !== questionSet.length - 1 ? (
            <button onClick={handleNextQuestionClick}>Next Question <IoNavigateOutline /></button>
          ) : null}
          <button onClick={handleSubmitTest}>Submit exam <IoNavigateOutline /></button>
        </div>
      </div>
    </div>
  );
};

export default TestSession;
