import React, { useState, useEffect } from "react";
import "./profile-edit.css";
import "../../../helpers/shared-css/dashboard-styling.css";
import { CiUser } from "react-icons/ci";
import { MdOutlineMail } from "react-icons/md";
import { FaRegFlag } from "react-icons/fa";
import { MdSchool } from "react-icons/md";
import { VscSymbolNamespace } from "react-icons/vsc";
import { LiaBirthdayCakeSolid } from "react-icons/lia";
import { FaPhoneAlt } from "react-icons/fa";
import { FaTransgender } from "react-icons/fa";
import {
    parsePhoneNumber,
    getCountries,
    getCountryCallingCode,
} from "libphonenumber-js";
import { fetchRequest, Paths } from "../../../helpers"
import Spinner from "../../../spinner/spinner";

const data = [
    ["username", "email", "country", "university"],
    ["fullName", "dob", "phoneNumber", "gender"]
]

const titles = [
    [{ title: "Username:", icon: <CiUser /> }, { title: "Email:", icon: <MdOutlineMail /> }, { title: "Country:", icon: <FaRegFlag /> }, { title: "University:", icon: <MdSchool /> }],
    [{ title: "Full name:", icon: <VscSymbolNamespace /> }, { title: "Date of birth:", icon: <LiaBirthdayCakeSolid /> }, { title: "Phone number:", icon: <FaPhoneAlt /> }, { title: "Gender:", icon: <FaTransgender /> }]
]

function isDateInPast(dateString) {
    const date = new Date(dateString);

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    return date < currentDate;
}

const deconstructPhoneNumber = (phoneNumber) => {
    if (phoneNumber === undefined) {
        return {
            countryCode: "+1",
            nationalNumber: "",
        }
    }
    try {
        const parsedNumber = parsePhoneNumber(phoneNumber);

        const countryCode = `+${parsedNumber.countryCallingCode}`;
        const nationalNumber = parsedNumber.nationalNumber;
        return {
            countryCode,
            nationalNumber,
        }
    } catch (error) {
        return {
            countryCode: "+1",
            nationalNumber: "",
        }
    }
}

const ProfileEdit = ({ idToken, generalTableData, setInEditMode, setInError, setGeneralTableData }) => {
    const fullName = generalTableData["fullName"];
    const dob = generalTableData["dob"];
    const phoneNumber = generalTableData["phoneNumber"];
    const gender = generalTableData["gender"];
    const allowSendingOfEmails = generalTableData["allowSendingOfEmails"];
    const allowSharingOfInformation = generalTableData["allowSharingOfInformation"];

    const { countryCode, nationalNumber } = deconstructPhoneNumber(phoneNumber);

    const [isLoading, setIsLoading] = useState(false);
    const [selectedGender, setSelectedGender] = useState(gender ?? "");
    const [selectedCountryCode, setSelectedCountryCode] = useState(countryCode);
    const [countryCodes, setCountryCodes] = useState([]);
    const [inputPhoneNumber, setInputPhoneNumber] = useState(nationalNumber);
    const [inputFullName, setInputFullName] = useState(fullName ?? "");
    const [dateOfBirth, setDateOfBirth] = useState(dob ?? "");
    const [shareResults, setShareResults] = useState(allowSharingOfInformation === true ? "Yes" : "No");
    const [emailUpdates, setEmailUpdates] = useState(allowSendingOfEmails === true ? "Yes" : "No");

    const [invalidPhoneNumber, setInvalidPhoneNumber] = useState(false);
    const [invalidDate, setInvalidDate] = useState(false);

    useEffect(() => {
        const countryList = getCountries();
        setCountryCodes(countryList);
    }, []);

    const handleCountryCodeChange = (event) => {
        setSelectedCountryCode(event.target.value);
    };

    const inputPhoneNumberChange = (number) => {
        setInputPhoneNumber(number);
        setInvalidPhoneNumber(false);
        document.getElementById("phonenumber").style.border = '1px solid rgb(186, 185, 185)';
    }

    const onSaveClick = async () => {
        document.getElementById("phonenumber").style.border = '1px solid rgb(186, 185, 185)';
        document.getElementById("dob").style.border = '1px solid rgb(186, 185, 185)';
        setInvalidDate(false);
        setInvalidPhoneNumber(false);

        const fullName = document.getElementById('fullname').value;
        const gender = selectedGender;
        const dob = dateOfBirth;
        const allowSharingOfInformation = shareResults === "No" ? false : true;
        const allowSendingOfEmails = emailUpdates === "No" ? false : true;

        const newData = {};

        const number = `${selectedCountryCode}${inputPhoneNumber.length < 1 ? "" : inputPhoneNumber}`;
        if (inputPhoneNumber === "" || inputPhoneNumber === undefined) {
            newData["phoneNumber"] = undefined;
            generalTableData["phoneNumber"] = undefined;
        } else {
            try {
                const parsedNumber = parsePhoneNumber(number);

                if (parsedNumber.isValid()) {
                    newData["phoneNumber"] = number;
                    generalTableData["phoneNumber"] = number;
                } else {
                    setInvalidPhoneNumber(true);
                    document.getElementById("phonenumber").style.border = '2px solid red';
                    return
                }
            } catch (error) {
                setInvalidPhoneNumber(true);
                document.getElementById("phonenumber").style.border = '2px solid red';
                return
            }
        }

        if (!isDateInPast(dob) && dob !== "") {
            setInvalidDate(true);
            document.getElementById("dob").style.border = '2px solid red';
            return
        }

        newData["fullName"] = fullName === "" ? undefined : fullName;
        newData["dob"] = dob === "" ? undefined : dob;
        newData["gender"] = gender === "" ? undefined : gender;
        newData["allowSharingOfInformation"] = allowSharingOfInformation;
        newData["allowSendingOfEmails"] = allowSendingOfEmails;

        setIsLoading(true);
        const response = await fetchRequest(idToken, "updateProfile", "POST", newData);
        if (response.status === 200) {
            setInEditMode(false);
            setIsLoading(false);
            generalTableData["fullName"] = fullName;
            generalTableData["dob"] = dob;
            generalTableData["gender"] = gender;
            generalTableData["allowSharingOfInformation"] = allowSharingOfInformation;
            generalTableData["allowSendingOfEmails"] = allowSendingOfEmails;
            setGeneralTableData(generalTableData);

        } else {
            setInError(true);
        }
    }

    const handleGenderChange = (event) => {
        setSelectedGender(event.target.value);
    };

    const dateOfBirthChange = (d) => {
        setDateOfBirth(d);
        setInvalidDate(false);
        document.getElementById("dob").style.border = '1px solid rgb(186, 185, 185)';
    }

    return (
        <div className="profile-edit-root">
            <div className="profile-edit">
                {
                    data.map((set, index) => {
                        return (
                            <div className="profile-set">
                                <div className="profile-titles">
                                    {
                                        titles[index].map((title, i) => {
                                            return (<p>{title.icon}{title.title}</p>)
                                        })
                                    }
                                </div>
                                <div className="profile-values">
                                    {
                                        set.map(title => {
                                            const value = generalTableData[title];
                                            if (title === "fullName") {
                                                return (
                                                    <input
                                                        className="fullname-input"
                                                        type="text"
                                                        id="fullname"
                                                        name="fullname"
                                                        placeholder="John Doe"
                                                        value={inputFullName}
                                                        onChange={(e) => setInputFullName(e.target.value)}
                                                    />
                                                )
                                            } else if (title === "phoneNumber") {
                                                return (
                                                    <div className="edit-mode-phone-number">
                                                        <select
                                                            id="countrycode"
                                                            className="edit-mode-country-code-input"
                                                            value={selectedCountryCode}
                                                            onChange={handleCountryCodeChange}
                                                        >
                                                            {countryCodes.map((country) => (
                                                                <option
                                                                    key={country}
                                                                    value={`+${getCountryCallingCode(country)}`}
                                                                >
                                                                    {`${country} (+${getCountryCallingCode(country)})`}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <input
                                                            type="text"
                                                            id="phonenumber"
                                                            className="edit-mode-phone-number-input"
                                                            placeholder="123456789"
                                                            value={inputPhoneNumber}
                                                            onChange={(e) => inputPhoneNumberChange(e.target.value)}
                                                        />
                                                    </div>
                                                )
                                            } else if (title === "dob") {
                                                return (
                                                    <input
                                                        type="date"
                                                        id="dob"
                                                        onChange={(e) => dateOfBirthChange(e.target.value)}
                                                    />
                                                )
                                            } else if (title === "gender") {
                                                return (
                                                    <div>
                                                        <div>
                                                            <label className="gender-radio-label">
                                                                <input
                                                                    type="radio"
                                                                    value="Male"
                                                                    checked={selectedGender === 'Male'}
                                                                    onChange={handleGenderChange}
                                                                />
                                                                Male
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label className="gender-radio-label">
                                                                <input
                                                                    type="radio"
                                                                    value="Female"
                                                                    checked={selectedGender === 'Female'}
                                                                    onChange={handleGenderChange}
                                                                />
                                                                Female
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label className="gender-radio-label">
                                                                <input
                                                                    type="radio"
                                                                    value="Other"
                                                                    checked={selectedGender === 'Other'}
                                                                    onChange={handleGenderChange}
                                                                />
                                                                Other
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label className="gender-radio-label">
                                                                <input
                                                                    type="radio"
                                                                    value=""
                                                                    checked={selectedGender === ""}
                                                                    onChange={handleGenderChange}
                                                                />
                                                                None
                                                            </label>
                                                        </div>
                                                    </div>
                                                )
                                            }

                                            return (<p className="profile-values-p">{value}</p>)
                                        })
                                    }
                                </div>
                            </div>
                        );
                    })
                }
            </div>
            <div>
                <div className="consents-div">
                    I allow IBIS to share my results and contact information with IBIS
                    recruiters.
                    <label>
                        <input
                            type="radio"
                            value="Yes"
                            checked={shareResults === 'Yes'}
                            onChange={(e) => setShareResults(e.target.value)}
                        />
                        Yes
                    </label>
                    <label>
                        <input
                            type="radio"
                            value="No"
                            checked={shareResults === 'No'}
                            onChange={(e) => setShareResults(e.target.value)}
                        />
                        No
                    </label>
                </div>
                <div className="consents-div">
                    I allow IBIS to send me emails about updates for new tests and other
                    things.
                    <label>
                        <input
                            type="radio"
                            value="Yes"
                            checked={emailUpdates === 'Yes'}
                            onChange={(e) => setEmailUpdates(e.target.value)}
                        />
                        Yes
                    </label>
                    <label>
                        <input
                            type="radio"
                            value="No"
                            checked={emailUpdates === 'No'}
                            onChange={(e) => setEmailUpdates(e.target.value)}
                        />
                        No
                    </label>
                </div>
            </div>
            <div className="save-edit">
                <button onClick={!isLoading ? onSaveClick : null}>{!isLoading ? "Save" : <Spinner />}</button>
                {
                    invalidPhoneNumber ?
                        <p className="invalid-input-message">The phone number you have entered is invalid.</p>
                        : invalidDate ?
                            <p className="invalid-input-message">The date you have entered is invalid.</p>
                            : null
                }
            </div>
        </div>
    );
};

export default ProfileEdit;
