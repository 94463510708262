import React from 'react';
import { Line } from 'react-chartjs-2';
import "../line-chart.css";
import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title, Tooltip, Legend } from 'chart.js';
import { sortDates } from '../../../helpers';

ChartJS.register(LineElement, PointElement, LinearScale, Title, Tooltip, Legend);

const AverageHistory = ({ userResults }) => {
    const dates = userResults.map(test => { return test.dateWritten })
    const yaxis = userResults.map(test => { return test['overall-average'] })
    const xaxis = sortDates(dates);
    const data = {
        labels: xaxis,
        datasets: [
            {
                label: 'Average',
                data: yaxis,
                fill: false,
                borderColor: 'rgb(255, 99, 132)',
                tension: 0.1,
            },
        ],
    };

    const options = {
        responsive: true,
        scales: {
            y: {
                min: 0,
                max: 100,
                ticks: {
                    callback: function (value) {
                        return value + "%";
                    },
                    font: {
                        weight: "bold",
                        size: "8%",
                    },
                    color: "rgb(91, 88, 88)",
                },
            },
            x: {
                ticks: {
                    font: {
                        weight: "bold",
                        size: "8%",
                    },
                    color: "rgb(91, 88, 88)",
                },
            },
        },
        plugins: {
            legend: {
                position: 'top',
                display: false,
            },
            title: {
                display: true,
            },
        },
    };

    return (
        <div className="line-chart">
            <Line options={options} data={data} height={175}/>
        </div>
    );
};

export default AverageHistory;