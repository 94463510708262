import React from "react";
import "@aws-amplify/ui-react/styles.css";
import "./graphs.css";
import "../../helpers/shared-css/dashboard-styling.css";
import ScoreBarChart from "./1. score-bar-chart/score-bar-chat";
import SectionBarChart from "./2. section-bar-chart/section-bar-chart";
import TimeBarChart from "./3. time-taken-chart/time-taken-chart";
import AverageHistory from "./4. average-history/average-history";
import SectionHistory from "./5. section-history/section-history";

const TEST_ID = "IBIS100";

export const Graphs = ({ generalTableData, allResults }) => {
    const username = generalTableData.username;
    const country = generalTableData.country;

    const userResults =
        Object.keys(allResults).length !== 0 ? allResults[`${TEST_ID}-Graphs`]["allUserResults"] : [];
    const usersBestResult = userResults.reduce((max, item) =>
        item["overall-average"] > max["overall-average"] ? item : max, userResults[0]
    ) ?? [];
    const userTime = usersBestResult.timeTaken;

    const countryResults = Object.keys(allResults).length !== 0 ? allResults[`${TEST_ID}-Graphs`]["countryResult"] : [];
    const countryTimeAverage =
        countryResults["sum-of-test-time-taken"] / countryResults["tests-written"];

    const globalResults =
        Object.keys(allResults).length !== 0 ? allResults[`${TEST_ID}-Graphs`]["globalResult"] : [];
    const globalTimeAverage =
        globalResults["sum-of-test-time-taken"] / globalResults["tests-written"];

    const sections = globalResults.sections ?? [];
    const userSections = [];
    const globalSections = [];
    const countrySections = [];

    for (const section of sections) {
        userSections.push(usersBestResult[`${section}-average`]);
    }
    for (const section of sections) {
        globalSections.push(globalResults[`${section}-average`]);
    }
    for (const section of sections) {
        countrySections.push(countryResults[`${section}-average`]);
    }

    return (
        <div className="graphs-root">
            <div className="score-graph">
                <h3 className="dashboard-text-h3-styling" style={{flexDirection: 'row'}}>Score <strong className="graph-label">(best result)</strong></h3>
                <p className="dashboard-text-p-styling">Compare your score against your country and global average.</p>
                <ScoreBarChart
                    userAverage={usersBestResult["overall-average"]}
                    globalAverage={globalResults["overall-average"]}
                    countryAverage={countryResults["overall-average"]}
                    username={username}
                    country={country}
                />
            </div>
            <div className="section-graph">
                <h3 className="dashboard-text-h3-styling">Section scores <strong className="graph-label">(best result)</strong></h3>
                <p className="dashboard-text-p-styling">Compare each section score against your country and global section averages.</p>
                <SectionBarChart
                    userSections={userSections}
                    globalSections={globalSections}
                    countrySections={countrySections}
                    sections={sections}
                    username={username}
                    country={country}
                />
            </div>
            <div className="time-graph">
                <h3 className="dashboard-text-h3-styling">Time taken <strong className="graph-label">(best result)</strong></h3>
                <p className="dashboard-text-p-styling">Compare the time you took to your country and global averages.</p>
                <TimeBarChart
                    userTime={userTime}
                    countryTimeAverage={countryTimeAverage}
                    globalTimeAverage={globalTimeAverage}
                    testLength={globalResults.testLength}
                    username={username}
                    country={country}
                />
            </div>
            <div className="average-history-graph">
                <h3 className="dashboard-text-h3-styling">Average history</h3>
                <p className="dashboard-text-p-styling">See your averages for each IBIS100 test taken.</p>
                <AverageHistory userResults={userResults} />
            </div>
            <div className="section-history-graph">
                <h3 className="dashboard-text-h3-styling">Section history</h3>
                <p className="dashboard-text-p-styling">See your section averages for each IBIS100 test taken.</p>
                <SectionHistory userResults={userResults} sections={sections} />
            </div>
        </div>
    );
};

export default Graphs;
