import React from "react";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../../helpers/constants";
import "./password-reset.css";

const PasswordReset = () => {
    const navigate = useNavigate();

    const navigateToLogin = () => {
        navigate(Paths.Login);
    }

    return (
        <div className="password-reset-message">
            <h1>You have successfully reset your password. Click below to login with your new password:</h1>
            <button onClick={navigateToLogin}>
                Login
            </button>
        </div>
    );
};

export default PasswordReset;
