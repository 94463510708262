import React, { useState } from "react";
import "./enter-username.css";
import { resetPassword } from "@aws-amplify/auth";

const EnterUsername = ({ setEmailSent, setEmail }) => {
  const [validEmail, setValidEmail] = useState(true);

  const sendEmail = async () => {
    const email = document.getElementById('email').value;

    try {
      await resetPassword({ username: email });
      setEmailSent(true);
      setEmail(email);
    } catch (error) {
      setValidEmail(false);
    }
  }

  return (
    <div className="enter-username-root">
      <h1>Please the email address your IBIS100 account is associated with:</h1>
      <div className="enter-username-information">
        <p>A pin will be sent to this email address allowing you to reset your password on the next page.</p>
      </div>
      <div className="enter-username-div">
        <input
          id="email"
          type="text"
          autocomplete="off"
          placeholder="john.doe@gmail.com" />
        <button onClick={sendEmail}>Continue</button>
      </div>
      {
        validEmail ? null :
        <p className="invalid-email-message">This email address does not match an IBIS100 account</p>
      }
    </div>
  );
};

export default EnterUsername;
