import React from "react";
import "./profile-header.css";
import { CgProfile } from "react-icons/cg";
import "../../../helpers/shared-css/dashboard-styling.css";

const ProfileHeader = ({ generalTableData, inEditMode, setInEditMode }) => {
    const username = generalTableData.username;
    const university = generalTableData.university;

    const changeEditMode = () => {
        setInEditMode(!inEditMode)
    }

    return (
        <div className="profile-header-root">
            <CgProfile />
            <div className="profile-username">
                <h3 className="dashboard-text-h3-styling">{username}</h3>
                <p className="dashboard-text-p-styling">{university}</p>
            </div>
            <div className="profile-navigation">
                {
                    !inEditMode ? <button onClick={changeEditMode}>Edit profile</button> : null
                }
            </div>
        </div>
    );
};

export default ProfileHeader;
