import React from "react";
import "./terms-and-conditions.css";
import "../../../helpers/shared-css/dashboard-styling.css";

const TermsAndConditions = ({isChecked, setIsChecked}) => {
    const checkboxChange = () => {
        setIsChecked(!isChecked);
    }

    return (
        <div className="terms-and-conditions-root">
            <div className="dashboard-text-header-div-styling">
                <h3 className="dashboard-text-h3-styling">Agree to the terms and conditions</h3>
                <p className="dashboard-text-p-styling">Before purchasing your test, please read the information below carefully and agree to it.</p>
            </div>
            <div className="tandc-div">
                <div className="tandc-section">
                    <p className="tandc-header">1. Test details</p>
                    <p className="tandc-information">
                        &bull; <strong style={{ fontWeight: 400 }}>Duration:</strong> The test will take 100 minutes to
                        complete.
                    </p>
                    <p className="tandc-information">
                        &bull; <strong style={{ fontWeight: 400 }}>Number of Questions:</strong> The test consists of 100
                        questions covering various topics.
                    </p>
                </div>
                <div className="tandc-section">
                    <p className="tandc-header">2. Preparation</p>
                    <p className="tandc-information">
                        &bull; <strong style={{ fontWeight: 400 }}>Calculator:</strong> It is recommended that you have
                        a calculator available during the test for calculations. This will
                        help you manage time and accuracy.
                    </p>
                </div>
                <div className="tandc-section">
                    <p className="tandc-header">3. During the test</p>
                    <p className="tandc-information">
                        &bull; <strong style={{ fontWeight: 400 }}>No Rewrites:</strong> If you close / refresh the test tab
                        during the exam, the test will automatically end, and you will not
                        be able to rewrite or redo the test. You will need to purchase a new
                        test if you wish to try again.
                    </p>
                    <p className="tandc-information">
                        &bull; <strong style={{ fontWeight: 400 }}>Review Questions:</strong> In the top right hand corner, you have the ability to
                        review previously answered questions before submitting your test.
                        This ensures that you are confident with your answers before
                        finalizing.
                    </p>
                </div>
                <div className="tandc-section">
                    <p className="tandc-header">4. After the test</p>
                    <p className="tandc-information">
                        &bull; <strong style={{ fontWeight: 400 }}>Results & Feedback:</strong> After completing the
                        test, you will receive an email containing your score, a review of
                        sections where you can improve. You will also be able to see your progress against other candidates who that have taken the same test.
                    </p>
                    <p className="tandc-information">
                        &bull; <strong style={{ fontWeight: 400 }}>Certificate:</strong> A personalized certificate will
                        be included in the email, visualizing your score and achievement in
                        the exam.
                    </p>
                </div>
                <div className="tandc-section">
                    <p className="tandc-header">Additional Tips:</p>
                    <p className="tandc-information">
                        &bull; Make sure you have a stable internet connection.</p>
                    <p className="tandc-information">
                        &bull; Ensure that your device is fully charged or plugged in to
                        avoid any interruptions.
                    </p>
                </div>
            </div>
            <div className="tandc-checkbox-div">
                <p className="tandc-checkbox-star">*</p>
                <input
                    type="checkbox"
                    id="terms+conditions-checkbox"
                    onChange={checkboxChange}
                />
                <label htmlFor="terms+conditions-checkbox">
                    I agree to the terms and conditions of this exam.
                </label>
            </div>
        </div>
    );
};

export default TermsAndConditions;
