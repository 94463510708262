import "./stripe-payment.css";
import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import Spinner from "../../../spinner/spinner";
import { FaTimes } from "react-icons/fa";

const stripePromise = loadStripe("pk_test_51QRuMtLalHikbP0ff90w7AWGRDdWFph0qybhGftUjgrUG2Q0Gj6y6h3iErQBfDAlW7wK6OgAf8f7zBeSO0jPWYXr00qUPiX1w1");

const StripePayment = ({
  setStripePayment,
  clientSecret,
}) => {
  const options = { clientSecret };

  const backOnClick = () => {
    setStripePayment(false);
  }

  const navigateToStripe = () => {
    const url = "https://stripe.com/";
    window.open(url, "_blank");
  }

  return (
    <div className="checkout-root">
      <div className="checkout-top">
        <button onClick={navigateToStripe}>We use the Stripe payment service.</button>
      </div>
      <div className="checkout" >
        <div className="checkout-form">
          <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        </div>
      </div>
      <div className="checkout-bottom">
        <button onClick={backOnClick}>Close</button>
      </div>
    </div>
  );
};

export default StripePayment;
