import React from "react";
import "@aws-amplify/ui-react/styles.css";
import "./stats.css";
import "../../../helpers/shared-css/dashboard-styling.css";
import { IoIosPeople } from "react-icons/io";
import { FaAward } from "react-icons/fa";

export const Stats = ({ allResults }) => {
    const globalResult = allResults["IBIS100-Graphs"]["globalResult"];
    const smallStats = [
        {
            text: "Members",
            value: globalResult["participationCount"],
            color: "green",
        },
        {
            text: "Certified members",
            value: globalResult["certifiedCount"],
            color: "blue",
        }
    ]

    return (
        <div className="stats-root">
            {
                smallStats.map(stat => {
                    return (
                        <div className={`small-stat-${stat.color}`}>
                            {
                                stat.text === "Members" ? <IoIosPeople /> : <FaAward />
                            }
                            <p className="dashboard-text-p-styling">{stat.text}</p>
                            <h3 className="dashboard-text-h3-styling">{stat.value}</h3>
                        </div>
                    )
                })
            }
            <div className="big-stat">

            </div>
        </div>
    );
};

export default Stats;
