import React from "react";
import "./dashboard-test-history.css";
import "../../../helpers/shared-css/dashboard-styling.css";
import { IoNavigateOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../../helpers";

const DashboardTestHistory = ({ generalTableData, setSelectedTestId, setSelectedTestNumber }) => {
    const navigate = useNavigate();

    const testsCompleted = generalTableData.testsCompleted.filter(test => {
        test["status"] = "Completed";
        if (test.id === 'IBIS100') return test;
    }) ?? [];

    const testsPurchased = generalTableData.testsPurchased.filter(test => {
        test["status"] = "Purchased";
        if (test.id === 'IBIS100') return test;
    }) ?? [];

    const combinedTests = [...testsPurchased, ...testsCompleted];
    const tests = combinedTests.map(test => {
        if (test["completedDate"] === undefined) {
            test["completedDate"] = "-";
        }
        if (test["purchasedDate"] === undefined) {
            test["purchasedDate"] = "-";
        }
        return test
    });

    const navigateToTestPortal = (testNumber) => {
        setSelectedTestId("IBIS100");
        setSelectedTestNumber(testNumber);
        const params = new URLSearchParams({
            previousUrl: 'home',
        });
        navigate(`${Paths.TestPortal}?${params.toString()}`);
    }
    const navigateToResults = () => {
        navigate(Paths.AnalyticsLeaderboard);
    }

    return (
        <div className="dashboard-test-history-root">
            <div className="dashboard-text-header-div-styling">
                <h3 className="dashboard-text-h3-styling">Exam history</h3>
                <p className="dashboard-text-p-styling">Explore all your tests that have been purchased and completed</p>
            </div>
            <div className="dashboard-test-history-table">
                <div className="dashboard-test-history-heading">
                    <p>Test ID</p>
                    <p>Purchased date</p>
                    <p>Completed date</p>
                    <p>Status</p>
                    <p></p>
                </div>
                <div className="dashboard-text-history-items">
                    {
                        tests.map(test => {
                            return (
                                <div className="dashboard-text-history-item">
                                    <p>{test.testNumber}</p>
                                    <p>{test.purchasedDate}</p>
                                    <p>{test.completedDate}</p>
                                    <p>{test.status}</p>
                                    <p>
                                        <button className="test-history-button" onClick={() => test.status === "Completed" ? navigateToResults() : navigateToTestPortal(test.testNumber)}>{test.status === "Completed" ? "See results" : "Begin test"}<IoNavigateOutline /></button>
                                    </p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default DashboardTestHistory;
