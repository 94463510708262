import React from "react";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import "./custom-authenticator.css";

export const CustomAuthenticator = () => {
  return (
    <div className="custom-auth-container">
      <Authenticator
        components={{
          SignIn: {
            Header() {
              return (
                <div className="logo">
                  <h1>IBIS&trade;</h1>
                </div>
              );
            },
            FormFields({ fields }) {
              return fields.map((field) => (
                <div key={field.name} className="amplify-input-container">
                  <input
                    {...field}
                    className="amplify-input"
                    style={{ paddingRight: "40px" }}
                  />
                  {field.type === "password" && (
                    <div className="password-icon">👁</div>
                  )}
                </div>
              ));
            },
            Footer() {
              return (
                <div className="custom-footer">
                  <a href="/forgot-password">Forgot your password?</a>
                </div>
              );
            },
          },
        }}
      />
    </div>
  );
};

export default CustomAuthenticator;
