import React from "react";
import "./dashboard-heading.css";
import "../../../helpers/shared-css/dashboard-styling.css";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../../helpers";

const DashboardHeading = ({ generalTableData, setSelectedTestId, setSelectedTestNumber, setHighlightPurchaseTest }) => {
    const navigate = useNavigate();

    const searchChange = () => {
        const searchValue = document.getElementById('search').value;
    }

    const beginTest = () => {
        const testsPurchased = generalTableData.testsPurchased.filter(test => {
            if (test.id === 'IBIS100') return test;
        }) ?? [];

        if (testsPurchased.length > 0) {
            setSelectedTestId(testsPurchased[0].id);
            setSelectedTestNumber(testsPurchased[0].testNumber);
            const params = new URLSearchParams({
                previousUrl: 'home',
            });
            navigate(`${Paths.TestPortal}?${params.toString()}`);
        } else {
            setHighlightPurchaseTest(true);
        }
    }

    return (
        <div className="dashboard-heading-root">
            <div className="dashboard-text-header-div-styling">
                <h3 className="dashboard-text-h3-styling">Hi, {generalTableData.username}</h3>
                <p className="dashboard-text-p-styling">Welcome back to IBIS!</p>
            </div>
            <div className="dashboard-heading-configurations">
                <input
                    type="text"
                    id="search"
                    placeholder=" 🔍  Search"
                    onChange={searchChange}
                    required
                />
                <button onClick={beginTest}>
                    Begin test
                </button>
            </div>
        </div>
    );
};

export default DashboardHeading;
