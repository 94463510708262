import React from "react";
import "@aws-amplify/ui-react/styles.css";
import "./leaders.css";
import "../../../helpers/shared-css/dashboard-styling.css";
import { GiTrophyCup } from "react-icons/gi";
import { GiPodiumSecond, GiPodiumWinner, GiPodiumThird } from "react-icons/gi";

export const Leaders = ({ allResults }) => {
    const leaders = allResults["IBIS100-Leaderboard"] ?
        allResults["IBIS100-Leaderboard"].slice(0, 3) : [];
    return (
        <div className="leaders-root">
            <div className="dashboard-text-header-div-styling">
                <h3 className="dashboard-text-h3-styling">Current leaders</h3>
            </div>
            <div className="leaders">
                {
                    leaders.map(leader => {
                        return (
                            <div className="leader">
                                <div className="leader-header">
                                    {
                                        leader["rank"] === 1 ? 
                                            <GiPodiumWinner className="leader-icon" /> 
                                        : leader["rank"] === 2 ? 
                                            <GiPodiumSecond className="leader-icon" />
                                        : 
                                            <GiPodiumThird className="leader-icon" />
                                    }
                                    <div className="leader-header-info">
                                        <p className="dashboard-text-p-styling">{leader["username"]}</p>
                                        <h3 className="dashboard-text-h3-styling" style={{textAlign: "start"}}>{`${parseFloat(leader["score"]).toFixed(2)}%`}</h3>
                                    </div>
                                    <h3 className="dashboard-text-h3-styling">{leader.rank === 1 ? `${leader.rank}st` : leader.rank === 2 ? `${leader.rank}nd` : `${leader.rank}rd`}</h3>
                                </div>
                                <div className="leader-stats">
                                    <div className="leader-stat">
                                        <p className="dashboard-text-p-styling">University</p>
                                        <h3 style={{textAlign: "start"}}>{leader["university"]}</h3>
                                    </div>
                                    <div className="leader-stat">
                                        <p className="dashboard-text-p-styling">Time taken</p>
                                        <h3 style={{textAlign: "start"}}>{`${parseFloat(leader["timeTaken"] / 60).toFixed(2)} mins`}</h3>
                                    </div>
                                    <div className="leader-stat">
                                        <p className="dashboard-text-p-styling">Date taken</p>
                                        <h3 style={{textAlign: "start"}}>{leader["date"]}</h3>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};

export default Leaders;
