import React, { useState } from "react";
import "@aws-amplify/ui-react/styles.css";
import "./feedback.css";
import "../../../helpers/shared-css/dashboard-styling.css";
import { IoNavigateOutline } from "react-icons/io5";

const TEST_ID = "Practice test";

export const Feedback = ({ allResults }) => {
    const userAnswers = allResults[`${TEST_ID}-Graphs`]["allUserResults"][0]["answers"];
    const examFeedback = allResults["Practice-test-exam"]
    const [index, setIndex] = useState(0);
    const maxIndex = examFeedback["questions"].length - 1;

    const nextQuestions = (scrollLeft) => {
        if (scrollLeft && index > 0) {
            setIndex(index - 1);
        } else if (!scrollLeft && index < maxIndex) {
            setIndex(index + 1);
        }
    }

    const question = examFeedback["questions"][index];
    const correctAnswer = question["answer"];
    const userAnswer = userAnswers[index];
    const userAnsweredCorrect = userAnswer === correctAnswer;
    const possibleAnswers = question["answers"];
    const explanation = question["explanation"];
    return (
        <div className="feedback-root">
            <div className="dashboard-text-header-div-styling">
                <h3 className="dashboard-text-h3-styling">Test feedback</h3>
                <p className="dashboard-text-p-styling">See the correct answers and explanations for your test feedback.</p>
            </div>
            <div className="feedback">
                <div className="feedback-question-navigation">
                    <button className={index === 0 ? "feedback-question-navigation-button-disabled" : "feedback-question-navigation-button"} onClick={() => nextQuestions(true)}>Previous question <IoNavigateOutline /></button>
                    <button className={index === maxIndex ? "feedback-question-navigation-button-disabled" : "feedback-question-navigation-button"} onClick={() => nextQuestions(false)}>Next question <IoNavigateOutline /></button>
                </div>
                <div className="question">
                    <div className="question-div">
                        <p className="question-title">Question:</p>
                        <p className="question-text">{question.questionText}</p>
                    </div>
                </div>
                {
                    possibleAnswers.map((answer, counter) => {
                        if (userAnsweredCorrect && answer === userAnswer) {
                            return (
                                <div className="answer-div">
                                    <div className="correct-answer-border">
                                        <strong>{counter + 1}.</strong> {answer}
                                        <div className="answer-label">
                                            <p className="correct-label">Your answer + correct answer 🎉</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        } else if (!userAnsweredCorrect && answer == userAnswer) {
                            return (
                                <div className="answer-div">
                                    <div className="incorrect-answer-border">
                                        <strong>{counter + 1}.</strong> {answer}
                                        <div className="answer-label">
                                            <p className="incorrect-label">Your answer</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        } else if (!userAnsweredCorrect && answer == correctAnswer) {
                            return (
                                <div className="answer-div">
                                    <div className="correct-answer-border">
                                        <strong>{counter + 1}.</strong> {answer}
                                        <div className="answer-label">
                                            <p className="correct-label">Correct answer</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div className="answer-div">
                                    <div className="non-special-answer">
                                        <strong>{counter + 1}.</strong> {answer}
                                    </div>
                                </div>
                            )
                        }
                    })
                }
                <div className="explanation">
                    <div className="explanation-div">
                        <p className="explanation-title">Explanation: </p>
                        <p className="explanation-text">{explanation}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Feedback;