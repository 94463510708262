import React from "react";
import "@aws-amplify/ui-react/styles.css";
import "./header.css";
import "../../../helpers/shared-css/dashboard-styling.css";

export const PracticeTestHeader = () => {
    return (
        <div className="practice-test-header-root">
            <div className="dashboard-text-header-div-styling">
                <h3 className="dashboard-text-h3-styling">Practice test</h3>
                <p className="dashboard-text-p-styling">The <strong>practice test</strong> allows you to familiarize yourself with the test format and content. It’s a great way to gauge your knowledge and identify areas for improvement before taking the main IBIS test. This free test is available anytime to support your preparation. You have one attempt, with 6 multiple-choice questions and a 6-minute time limit for each attempt.</p>
            </div>
        </div>
    );
};

export default PracticeTestHeader;
