import React, { useState, useEffect } from "react";
import "./ibis-loader.css";

const array = ["L", "o", "a", "d", "i", "n", "g", " ", "I", "B", "I", "S", ".", ".", ".", "."];

const IbisLoader = () => {
    const [index, setIndex] = useState(0);
    const [loader, setLoader] = useState("");

    useEffect(() => {
        const interval = setInterval(() => {
            setLoader((prevLoader) => prevLoader + array[index]);
            setIndex((prevIndex) => {
                if (prevIndex === array.length - 1) {
                    setLoader("");
                    return 0;
                }
                return prevIndex + 1;
            });
        }, 500);

        return () => clearInterval(interval);
    }, [index]);

    return (
        <div className="ibis-loading">
            <h1>{loader}</h1>
        </div>
    );
};

export default IbisLoader;
