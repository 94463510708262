import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./side-bar.css";
import "../helpers/shared-css/dashboard-styling.css";
import { FaRegUserCircle } from "react-icons/fa";
import { CiLogout } from "react-icons/ci";
import { Paths } from "../helpers/constants";
import { IoMenu } from "react-icons/io5";
import { RxDashboard } from "react-icons/rx";
import { MdOutlineAnalytics } from "react-icons/md";
import { TbBrandBooking } from "react-icons/tb";
import { CgProfile } from "react-icons/cg";
import { VscFeedback } from "react-icons/vsc";
import { GrTest } from "react-icons/gr";

const SideBar = ({ signOut, location, username, mobileSideBarNavigation }) => {
  const navigate = useNavigate();
  const activeLink = `#${location.split('/')[1]}`;
  const dropDownActiveLink = `#${location.split('/')[2]}` ?? "";
  const [openDropdown, setOpenDropdown] = useState(false);

  useEffect(() => {
    const specificItem = document.getElementById('analytics-li');
    
    if (!specificItem) return;

    const handleMouseOver = () => {
        specificItem.style.borderBottom = "1px solid gray";
        specificItem.style.borderLeft = "1px solid gray";
        specificItem.style.borderRight = "1px solid gray";
        specificItem.style.borderRadius = "5px";
        setOpenDropdown(true);
    };

    const handleMouseOut = () => {
        specificItem.style.border = "";
        setOpenDropdown(false);
    };

    specificItem.addEventListener('mouseover', handleMouseOver);
    specificItem.addEventListener('mouseout', handleMouseOut);

    return () => {
        specificItem.removeEventListener('mouseover', handleMouseOver);
        specificItem.removeEventListener('mouseout', handleMouseOut);
    };
}, []);

  const handleLinkClick = (event, path) => {
    event.preventDefault();
    navigate(`/${path}`);
  };

  const handleLogoutClick = async () => {
    if (signOut) {
      await signOut();
    }
  };

  const handleIconOnClick = () => {
    navigate(Paths.Profile);
  };

  const handleLogoOnClick = () => {
    navigate(Paths.Home)
  }

  const showSideBarNavigation = () => {
    navigate(Paths.MobileSideBarNavigation);
  }

  return (
    <div className={mobileSideBarNavigation ? "side-bar-nav" : "side-bar"}>
      <div className={mobileSideBarNavigation ? "side-bar-navigation" : "side-bar-mobile"}>
        <div className="user-icon-div-mobile">
          <FaRegUserCircle className="user-icon-mobile" onClick={handleIconOnClick} />
          <p>{username}</p>
        </div>
        <div className="side-bar-menu-mobile" onClick={showSideBarNavigation}>
          <IoMenu className="menu-mobile" />
        </div>
      </div>
      <div className={mobileSideBarNavigation ? "side-bar-mobile-navigation" : "side-bar-navigation"}>
        <div className="logo-div" onClick={handleLogoOnClick}>
          <h1>IBIS&trade;</h1>
        </div>
        <p className="side-bar-nav-title">Menu</p>
        <div className="side-bar-nav">
          <ul>
            <li>
              <a
                href="#home"
                onClick={(e) => handleLinkClick(e, "home")}
                className={activeLink === "#home" ? "active" : ""}
              >
                <p className={activeLink === "#home" ? "nav-label-active" : "nav-label"}><RxDashboard className="nav-label-icon" />Dashboard</p>
              </a>
            </li>
            <li>
              <a
                href="#booktest"
                onClick={(e) => handleLinkClick(e, "booktest")}
                className={activeLink === "#booktest" ? "active" : ""}
              >
                <p
                  className={activeLink === "#booktest" ? "nav-label-active" : "nav-label"}><TbBrandBooking className="nav-label-icon" />Book a Test</p>
              </a>
            </li>
            <li id="analytics-li">
              <a
                href="#analytics"
                onClick={(e) => handleLinkClick(e, "analytics/leaderboard")}
                className={activeLink === "#analytics" ? "active" : ""}
              >
                <p className={activeLink === "#analytics" ? "nav-label-active" : "nav-label"}><MdOutlineAnalytics className="nav-label-icon" />Analytics</p>
              </a>
              {
                activeLink === "#analytics" || openDropdown ?
                  <div className="analytics-nav-label">
                    <div
                      className={dropDownActiveLink === '#leaderboard' ? "analytics-nav-dropdown-label-active" : "analytics-nav-dropdown-label"}
                      onClick={(e) => handleLinkClick(e, "analytics/leaderboard")}
                    >
                      <p>Leaderboard</p>
                    </div>
                    <div
                      className={dropDownActiveLink === '#statistics' ? "analytics-nav-dropdown-label-active" : "analytics-nav-dropdown-label"}
                      onClick={(e) => handleLinkClick(e, "analytics/statistics")}
                    >
                      <p>Results and stats</p>
                    </div>
                  </div>
                  : null
              }
            </li>
            <li>
              <a
                href="#practiceTest"
                onClick={(e) => handleLinkClick(e, "practiceTest")}
                className={activeLink === "#practiceTest" ? "active" : ""}
              >
                <p className={activeLink === "#practiceTest" ? "nav-label-active" : "nav-label"}><GrTest className="nav-label-icon" />Practice test<strong style={{fontWeight: 300}}>(free)</strong></p>
              </a>
            </li>
          </ul>
        </div>
        <p className="side-bar-nav-title">Other</p>
        <div className="side-bar-nav">
          <ul>
            <li>
              <a
                href="#profile"
                onClick={(e) => handleLinkClick(e, "profile")}
                className={activeLink === "#profile" ? "active" : ""}
              >
                <p className={activeLink === "#profile" ? "nav-label-active" : "nav-label"}><CgProfile className="nav-label-icon" />Profile</p>
              </a>
            </li>
          </ul>
          <ul>
            <li>
              <a
                href="#feedback"
                onClick={(e) => handleLinkClick(e, "feedback")}
                className={activeLink === "#feedback" ? "active" : ""}
              >
                <p className={activeLink === "#feedback" ? "nav-label-active" : "nav-label"}><VscFeedback className="nav-label-icon" />Feedback</p>
              </a>
            </li>
          </ul>
        </div>
        <div className="logout-div" onClick={handleLogoutClick}>
          <button>
            <CiLogout className="logout" />
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
