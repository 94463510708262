import React from "react";
import "./dashboard-navigation.css";
import "../../../helpers/shared-css/dashboard-styling.css";
import { IoNavigateOutline } from "react-icons/io5";
import { Tooltip } from 'react-tooltip';
import { useNavigate } from "react-router-dom";
import { Paths } from "../../../helpers";

const DashboardNavigation = ({ generalTableData, setSelectedTestId, setSelectedTestNumber, highlightPurchaseTest }) => {
    const navigate = useNavigate();

    const navigateToBookATest = () => {
        navigate(Paths.BookATest);
    }
    const navigateToResults = () => {
        navigate(Paths.AnalyticsLeaderboard);
    }
    const navigateToTestPortal = () => {
        const testsPurchased = generalTableData.testsPurchased.filter(test => {
            if (test.id === 'IBIS100') return test;
        });
        setSelectedTestNumber(testsPurchased[0].testNumber);
        setSelectedTestId("IBIS100");
        const params = new URLSearchParams({
            previousUrl: 'home',
        });
        navigate(`${Paths.TestPortal}?${params.toString()}`);
    }

    const dashboardNavigationSteps = [
        {
            text: "1. Purchase a test",
            information: "Purchase the IBIS100 test securely through our stripe integration.",
            buttonText: "Purchase test",
        },
        {
            text: "2. Start a test",
            information: "Start the IBIS100 test.",
            buttonText: "Begin test",
        },
        {
            text: "3. See results",
            information: "See your results and statistics here.",
            buttonText: "See results",
        }
    ]

    const testsPurchased = generalTableData.testsPurchased.filter(test => {
        if (test.id === 'IBIS100') return test;
    }) ?? [];
    const testsCompleted = generalTableData.testsCompleted.filter(test => {
        if (test.id === 'IBIS100') return test;
    }) ?? [];

    return (
        <div className="dashboard-navigation-root">
            <div className="dashboard-text-header-div-styling">
                <h3 className="dashboard-text-h3-styling">Let's begin your IBIS journey</h3>
                <p className="dashboard-text-p-styling">To get ranked, start the IBIS100 test by following these 3 steps!</p>
            </div>
            <div className="dashboard-navigation-steps">
                {
                    dashboardNavigationSteps.map(step => {
                        const disableStartTestButton =
                            step.text === "2. Start a test" && testsPurchased.length === 0;
                        const disableSeeResultsButton =
                            step.text === "3. See results" && testsCompleted.length === 0;
                        const disabledButtonText = disableSeeResultsButton ? "You first need to start and complete an IBIS100 test to see other users results." :
                            disableStartTestButton ? "To begin a test you must first purchase one." : null;
                        return (
                            <div className="dashboard-step" style={{border: highlightPurchaseTest && step.buttonText === "Purchase test" ? "2px solid red" : ""}}>
                                <div className="dashboard-step-header">
                                    <h3 className="dashboard-text-h3-styling">{step.text}</h3>
                                    <p className="dashboard-text-p-styling">{step.information}</p>
                                </div>
                                <button
                                    id={step.buttonText}
                                    onClick={
                                        step.buttonText === "Purchase test" ?
                                            navigateToBookATest
                                            : step.buttonText === "Begin test" && !disableStartTestButton ?
                                                navigateToTestPortal
                                                : step.buttonText === "See results" && !disableSeeResultsButton ?
                                                    navigateToResults : null
                                    }
                                    data-tooltip-id={step.buttonText}
                                    data-tooltip-content={disabledButtonText}
                                    className={disableStartTestButton || disableSeeResultsButton ? "dashboard-step-button-disabled" : "dashboard-step-button"}
                                >
                                    {step.buttonText}<IoNavigateOutline />
                                </button>
                                {
                                    disableStartTestButton || disableSeeResultsButton ? <Tooltip id={step.buttonText} /> : null
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
};

export default DashboardNavigation;
