import React, { useEffect, useState } from "react";
import "@aws-amplify/ui-react/styles.css";
import "./analytics-leaderboard.css";
import "../../helpers/shared-css/dashboard-styling.css";
import "../../helpers/shared-css/no-data.css"
import { IoNavigateOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../helpers";
import Stats from "../components/1. stats/stats";
import Leaders from "../components/2. leaders/leaders";
import Leaderboard from "../components/3. leaderboard/leaderboard";

export const AnalyticsLeaderboard = ({ generalTableData, allResults }) => {
  const navigate = useNavigate();

  const [ibisLeaderboard, setIbisLeaderboard] = useState(0)

  useEffect(() => {
    if (allResults["IBIS100-Leaderboard"] !== undefined) {
      setIbisLeaderboard(allResults["IBIS100-Leaderboard"]);
    } else {
      setIbisLeaderboard([]);
    }
  }, [allResults]);

  const bookATestNavigation = () => {
    navigate(Paths.BookATest);
  }

  return (
    <div className="analytics-leaderboard-root">
      <div className="dashboard-text-header-div-styling">
        <h3 className="dashboard-text-h3-styling">Leaderboard</h3>
        <p className="dashboard-text-p-styling">The IBIS100 leaderboard ranks you based on your best IBIS100 test result.</p>
      </div>
      {
        ibisLeaderboard.length === 0 ?
          <div className="analytics-no-data">
            <h3 className="dashboard-text-h3-styling">You have not taken the IBIS100 test yet. To begin, purchase a test here:</h3>
            <button onClick={bookATestNavigation}>
              Book a test <IoNavigateOutline />
            </button>
          </div>
          :
          <div className="analytics-leaderboard">
            <Stats allResults={allResults}/>
            <Leaders allResults={allResults} />
            <Leaderboard allResults={allResults} generalTableData={generalTableData} /> 
          </div>
      }
    </div>
  );
};

export default AnalyticsLeaderboard;
